import { Typography } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useState } from 'react'

import Link from '../Link'
import Wrapper from '../UI/Wrapper'
import {
  CategoryLinksSection,
  Column,
  LinkGrid,
  RowBackground,
  RowGrid,
} from './styles'

interface CategoryLinksProps {
  headline: string
  image: {
    imgixImage: {
      gatsbyImageData: IGatsbyImageData
    }
    title: string
  }
  slides: [
    {
      link: {
        text: string
        internal_page: {
          id: string
          url: string
        }
      }
    },
  ]
}

const CategoryLinks = ({ headline, image, slides }: CategoryLinksProps) => {
  const [activeOverlay, setActiveOverlay] = useState('')
  const textColor = 'Dark'
  const textSize = 'Regular'
  return (
    <CategoryLinksSection>
      <RowBackground>
        <Wrapper>
          <RowGrid>
            <Column>
              <div>
                <Typography
                  className={`headline headline${textColor} headline${textSize}`}
                  component="h1"
                  variant="h1"
                >
                  {headline}
                </Typography>
                <LinkGrid>
                  {slides.map(
                    ({ link }) =>
                      link?.[0]?.internal_page?.[0]?.url &&
                      link?.[0]?.text && (
                        <Link
                          directUrl={link?.[0]?.internal_page?.[0]?.url}
                          key={link?.[0]?.text}
                          onBlur={() => setActiveOverlay('')}
                          onFocus={() => setActiveOverlay(link?.[0]?.text)}
                          onMouseLeave={() => setActiveOverlay('')}
                          onMouseOver={() => setActiveOverlay(link?.[0]?.text)}
                        >
                          <span>
                            {link?.[0]?.text}
                            <ArrowRightIcon className="arrow" />
                          </span>
                        </Link>
                      ),
                  )}
                </LinkGrid>
              </div>
            </Column>
            <Column className="center">
              {image?.imgixImage?.gatsbyImageData ? (
                <GatsbyImage
                  alt={headline}
                  image={image.imgixImage.gatsbyImageData}
                />
              ) : null}
            </Column>
          </RowGrid>
        </Wrapper>
      </RowBackground>
    </CategoryLinksSection>
  )
}

export default CategoryLinks
