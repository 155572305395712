import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import CategoryLinks from '../components/CategoryLinks'
import ExecutiveTeamGrid from '../components/ExecutiveTeamGrid'
import FeatureOverview from '../components/FeatureOverview'
import Hero from '../components/Hero'
import ImpactModule from '../components/Impact'
import KeyAreas from '../components/KeyAreas'
import Layout from '../components/Layout'
import LocationsGrid from '../components/LocationsGrid'
import PageBody from '../components/PageBody'
import RelatedResources from '../components/RelatedResources'
import RelatedSolutions from '../components/RelatedSolutions'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import StepForm from '../components/StepForm'
import TrainstopSection from '../components/TrainstopSection'
import Testimonial from '../components/UI/Testimonial'
import createDownloadObject from '../utils/createDownloadObject'
import { booleanFilter } from '../utils/helpers'

type ParentPage = { url: string; pageUrl: string; title: string }

const isParentPage = (page: unknown | ParentPage): page is ParentPage =>
  (page as ParentPage).url !== undefined &&
  ((page as ParentPage).pageUrl !== undefined ||
    (page as ParentPage).url !== undefined)

const topicHasHeadline = (
  topic: unknown | { headline: string },
): topic is { headline: string } =>
  (topic as { headline: string }).headline !== undefined

const topicHasSubheading = (
  topic: unknown | { subheading: string },
): topic is { subheading: string } =>
  (topic as { subheading: string }).subheading !== undefined

const topicHasFeatureImage = (
  topic: unknown | { feature_image: Queries.Query['contentstackAssets'] },
): topic is { feature_image: Queries.Query['contentstackAssets'] } =>
  (topic as { feature_image: Queries.Query['contentstackAssets'] })
    .feature_image !== undefined

const BusinessInfoPage = ({
  data,
  pageContext,
}: PageProps<
  Queries.BusinessInfoPageQuery,
  { filterType: string; slug: string }
>) => {
  const pageData = data?.contentstackPageBusinessInfo
  const breadcrumbLinks: { text: string; target?: string }[] = [
    { text: pageData?.title || '' },
  ]

  const parentPage = pageData?.parent_page?.[0]

  if (
    parentPage &&
    isParentPage(parentPage) &&
    (parentPage?.url || parentPage?.pageUrl)
  ) {
    breadcrumbLinks.unshift({
      text: parentPage?.title,
      target: parentPage?.pageUrl || parentPage?.url,
    })
  }

  let resourcesByFilter
  switch (pageContext.filterType) {
    case 'Contentstack_topic_resource_type':
      resourcesByFilter = [
        ...(data?.resourceCardsByResourceTypeFilter?.nodes ?? []),
      ]
      break
    case 'Contentstack_topic_industry':
      resourcesByFilter = [
        ...(data?.resourceCardsByIndustryFilter?.nodes ?? []),
      ]
      break
    case 'Contentstack_topic_role':
      resourcesByFilter = [...(data?.resourceCardsByRoleFilter?.nodes ?? [])]
      break
    default:
      resourcesByFilter = []
      break
  }

  const allPromotedResourceCards =
    data?.allPromotedResourceCards?.promoted_resources || []

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    (topicHasHeadline(pageData?.page_header?.[0]?.topic?.[0])
      ? pageData?.page_header?.[0]?.topic?.[0]?.headline
      : '')
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    (topicHasSubheading(pageData?.page_header?.[0]?.topic?.[0])
      ? pageData?.page_header?.[0]?.topic?.[0]?.subheading
      : '')
  const metaImg =
    pageData?.metadata?.image ??
    pageData?.page_header?.[0]?.feature_image ??
    (topicHasFeatureImage(pageData?.page_header?.[0]?.topic?.[0])
      ? pageData?.page_header?.[0]?.topic?.[0]?.feature_image
      : null)

  const downloadResourceObject = createDownloadObject(data, 'data')

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale ?? undefined}
      slug={pageContext.slug}
      title={metaTitle}
    >
      {pageData?.page_header?.[0]?.internal?.type ===
        'Contentstack_section_hero_banner' && (
        <Hero content={pageData?.page_header?.[0]} />
      )}

      {pageData?.optional_sections?.filter(booleanFilter).map((section) => (
        <React.Fragment key={section.id}>
          {section.internal?.type ===
            'Contentstack_section_feature_overview' && (
            <FeatureOverview
              body={
                section.overview_description ||
                section.topic?.[0]?.overview_description
              }
              headline={
                section.headline || section.topic?.[0]?.overview_headline
              }
              theme="White"
            />
          )}
          {section?.internal?.type === 'Contentstack_section_train_stop' && (
            <TrainstopSection
              ctaText={section.cta_text}
              headline={section?.headline}
              isHeroTrainStop={section.is_hero_train_stop}
              slides={section?.slides}
            />
          )}
          {section?.internal?.type === 'Contentstack_section_page_body' && (
            <PageBody
              content={section}
              // downloadUrl={downloadResourceObject.url}
              downloadResource={downloadResourceObject}
              noTopPadding={!pageData?.industry_segments?.[0]}
            />
          )}
          {section?.internal?.type === 'Contentstack_section_impact_stats' && (
            <ImpactModule content={section} />
          )}
          {section?.internal?.type === 'Contentstack_section_key_areas' && (
            <KeyAreas content={section} />
          )}
          {section?.internal?.type === 'Contentstack_section_lead_gen_form' &&
            section?.variant === 'Step' && <StepForm formData={section} />}
          {section?.internal?.type === 'Contentstack_section_testimonial' && (
            <Testimonial
              citation={section?.testimonial?.[0]?.source}
              testimonial={section?.testimonial?.[0]?.testimonial}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_resource_card_set' && (
            <RelatedResources
              content={{
                related_resources: resourcesByFilter || [],
                ...section,
              }}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_solutions_card_carousel' && (
            <RelatedSolutions relatedSolutions={section} />
          )}
          {section?.internal?.type ===
            'Contentstack_section_office_locations' && (
            <LocationsGrid offices={section?.offices} />
          )}

          {section?.internal?.type ===
            'Contentstack_section_category_links' && (
            <CategoryLinks
              headline={section?.headline}
              image={section?.feature_image}
              slides={section?.slides}
            />
          )}

          {section?.internal?.type === 'Contentstack_section_executive_team' &&
          section?.executives ? (
            <ExecutiveTeamGrid cards={section?.executives} />
          ) : null}
        </React.Fragment>
      ))}

      {(allPromotedResourceCards?.length > 0 ||
        resourcesByFilter?.length > 0) && (
        <RelatedResources
          content={{
            promoted_resources: allPromotedResourceCards || [],
            related_resources: resourcesByFilter || [],
            rows: pageData?.related_resources?.[0]?.rows || 1,
            headline: pageData?.related_resources?.[0]?.headline || '',
            cta_button: pageData?.related_resources?.[0]?.cta_button,
          }}
        />
      )}
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.BusinessInfoPageQuery>) => {
  const parentPage = data?.contentstackPageBusinessInfo
    ?.parent_page?.[0] as ParentPage

  return (
    <>
      <SchemaMarkup data={data.contentstackPageBusinessInfo?.schema_markup} />
      <SchemaMarkupBreadcrumb
        pageTitle={data?.contentstackPageBusinessInfo?.title}
        parent={{
          title: parentPage?.title,
          pageUrl: parentPage?.url,
        }}
      />
    </>
  )
}

export default BusinessInfoPage

export const query = graphql`
  query BusinessInfoPage(
    $pageId: String
    $filterField: String
    $promotedId: String
    $locale: String
    $downloadResourceId: String
  ) {
    contentstackPageBusinessInfo(
      id: { eq: $pageId }
      publish_details: { locale: { eq: $locale } }
    ) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      related_resources {
        headline
        rows
        cta_button {
          id
          text
          link {
            ...NavigationLinkFragment
          }
        }
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        ... on Contentstack_section_hero_banner {
          ...PageHeaderSection
        }
        #... on Contentstack_section_hero_carousel {
        #  headline
        #  max_slides
        #  type
        #  internal {
        #    type
        #  }
        #}
      }
      parent_page {
        ... on Contentstack_page_flexible {
          id
          pageUrl: url
          title
        }
        ... on Contentstack_page_business_info {
          id
          url
          title
        }
      }
      optional_sections {
        ... on Contentstack_section_impact_stats {
          ...ImpactStatsSection
        }
        ... on Contentstack_section_page_body {
          ...PageBodySection
        }
        ... on Contentstack_section_key_areas {
          ...KeyAreasSection
        }
        ... on Contentstack_section_train_stop {
          ...TrainStopSection
        }
        ... on Contentstack_section_feature_overview {
          ...FeatureOverviewSection
        }
        ... on Contentstack_section_lead_gen_form {
          ...LeadGenForm
        }
        ... on Contentstack_section_solutions_card_carousel {
          ...FeaturedProgramsSection
        }
        ... on Contentstack_section_office_locations {
          ...OfficeLocationsSection
        }
        ... on Contentstack_section_executive_team {
          ...ExecutiveTeamSection
        }
        ... on Contentstack_section_category_links {
          ...CategoryLinksSection
        }
      }
    }

    allPromotedResourceCards: contentstackSectionResourceCardSet(
      id: { eq: $promotedId }
    ) {
      ...RelatedResourcesSection
    }

    resourceCardsByResourceTypeFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: {
            resource_type: { elemMatch: { title: { eq: $filterField } } }
          }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByRoleFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { roles: { elemMatch: { title: { eq: $filterField } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByIndustryFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: {
            industry: { elemMatch: { title: { eq: $filterField } } }
          }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    productPromoPages: allContentstackPageProduct(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        id
        url
        product {
          id
        }
      }
    }

    downloadResourceCard: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: {
            is_gated: { eq: "Yes" }
            id: { eq: $downloadResourceId }
          }
        }
      }
    ) {
      nodes {
        title
        url
        resource {
          headline
          side_cta_short_description
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 374, imgixParams: { q: 72 })
            }
          }
        }
      }
    }

    allContentstackResourcesWebsite(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        download_card_cta_text
      }
    }
  }
`
