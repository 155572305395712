import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const CategoryLinksSection = styled.section`
  /* padding: 0 0 96px; */
  padding: 0 0 0;
  .headline {
    margin-bottom: 32px;
    max-width: 14ch;

    &.noImage {
      max-width: unset;
    }
    &.headlineLight {
      color: inherit;
    }
    &.headlineDark {
      color: ${rspTheme.palette.primary.main};
    }
    &.headlineLarge {
      font-size: 6.2rem;

      @media (max-width: 580px) {
        font-size: 4.6rem;
        line-height: 1.25;
      }
    }

    @media (max-width: 840px) {
      font-size: 2.8rem;
      letter-spacing: -0.2px;
      line-height: 1.35;
      margin-bottom: 16px;
      max-width: 28ch;

      &.noImage {
        max-width: unset;
      }
    }
  }
  .cardGrid {
    gap: 72px;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
      flex-basis: 30%;
      flex-grow: 1;
      max-width: 330px;
      min-width: 280px;
      margin: 0;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 0 0 72px;

    .cardGrid {
      gap: 16px;
    }
  }
`

export const RowBackground = styled.div`
  position: relative;
  overflow-x: visible;

  .gatsby-image-wrapper {
    margin-right: -5%;
  }
`

export const RowGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 24px;
  margin: 80px 0;
  text-align: left;

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    grid-template-columns: 1fr;
    margin: 24px 0 0;
  }
`

export const Column = styled.div`
  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    grid-row-start: 2;
    margin-top: 24px;
  }

  display: flex;
  align-items: center;

  &.center {
    text-align: center;
  }

  > * + h2 {
    margin-top: 88px;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      margin-top: 48px;
    }
  }
`

export const LinkGrid = styled.ul`
  margin: 40px 0 16px;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;

  a {
    color: inherit;
    display: inline-block;
    padding: 4px 24px 4px 8px;
    margin-left: -8px;
    border-radius: 4px;
    text-decoration-color: transparent;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: rgba(33, 21, 74, 0.1);
    }
    &:focus {
      background-color: rgba(33, 21, 74, 0.1);
      text-decoration-color: inherit;
    }

    span {
      position: relative;
    }

    .arrow {
      position: absolute;
      right: -22px;
      bottom: -2px;
    }
  }
`
